import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MatSlideToggleChange, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';
import { NotificationService } from 'src/app/service/notification.service';
import { SlotbookingService } from 'src/app/service/slotbooking.service';

@Component({
  selector: 'app-review',
  templateUrl: './review.component.html',
  styleUrls: ['./review.component.scss'],
})
export class ReviewComponent implements OnInit {
  obj: any;
  refferal:boolean = true;

  constructor(public dialog: MatDialog,public dialogRef: MatDialogRef<ReviewComponent>,@Inject(MAT_DIALOG_DATA) public data,private finishservice:SlotbookingService,private notification:NotificationService,private router: Router, ) 
  {
    console.log(data);
    this.obj = data;
   }

  ngOnInit() {
    console.log(this.obj)
  }

  onChange($event: MatSlideToggleChange) {
    console.log($event.checked);
    if($event.checked == true){
      this.finishservice.GoogleReview(this.obj).subscribe((data)=>{
        console.log(data)
        if(data['code']=='200'){
          this.notification.success("Google review request will be sent")
          // this.router.navigateByUrl('/appointment').then(() => {
          //   window.location.reload()
          // });
        }
      })
    }
}
Change(){
    if(this.obj.rfd_mobile != ""){
      window.open('http://web.whatsapp.com/send?phone=+91'+this.obj.rfd_mobile+'&text=Dear '+this.obj.rfd_doc_name+ ' Thank you for Referring this Patient (' +this.obj.patient_name+') \n' +this.obj.doctor_name+'','_blank','width=400px',)
      
    }
    // this.router.navigateByUrl('/appointment').then(() => {
    //   window.location.reload()
    // });
}

finish()
{
  // this.router.navigateByUrl('/appointment').then(() => {
  //   window.location.reload()
  // });
  console.log(localStorage.getItem('info'))
  this.finishservice.Finishappountment(this.obj).subscribe((res)=>{
    console.log(res)
    if(res['code'] == '200'){  
      this.Change()
      this.router.navigateByUrl('/appointment').then(() => {
        window.location.reload()
      });
    }
  })
}

closeModal()
{
  this.dialog.closeAll()
}
}
